<template>
  <v-layout
    id="loginImage"
    :style="{'background-image': 'url(' + image + ')'}"
    align-start
    justify-center
    fill-height
    layout="column">
    <img
      id="loginLogo"
      :src="logo">
  </v-layout>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
#loginLogo {
  margin-top: 376px;
}

#loginImage {
  background-size: cover;
  background-position-x: 99%;
}
</style>
