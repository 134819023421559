<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div id="loginForm">
    <v-form
      id="leftPart"
      ref="form"
      v-model="valid"
      name="loginForm"
      @submit.prevent="login">
      <v-layout
        column
        fill-height
      >

        <v-layout
          class="title"
          align-center
          justify-center
          fill-height
          column>
          <h1>{{ $t('login.formTitle') }}</h1>
          <v-divider/>
        </v-layout>

        <v-text-field
          id="inputIdentity"
          :label="$t('login.labelUsername')"
          v-model="username"
          :rules="[rules.required, rules.email]"
          class="default-input"
          type="email"
          autofocus
        />

        <password-field
          id="passwordField"
          ref="passwordField"
          :label="$t('login.labelPassword')"
          v-model="password"
          :rules="[rules.required]"
          name="password"
          class="default-input mt-0"
        />

        <v-layout
          justify-end
          class="mt-1 mb-4"
        >
          <a
            v-if="!isLdapActive"
            id="LoginFormForgotPasswordButton"
            data-cy="LoginFormForgotPasswordButton"
            ref="forgot-password"
            @click="showForgotPassword"
          >
            {{ $t('login.forgotPassword.title') }}
          </a>
        </v-layout>

        <v-layout
          class="mt-15"
          id="loginButtonLayout"
          align-center
          justify-center
          fill-height
        >
          <nerve-button
            id="loginButton"
            data-cy="iiotLoginFormLoginBtn"
            :disabled="!valid || !isEmailValid"
            :text="$t('login.loginBtn')"
            type-of-btn="action"
            size="normal"
            class="mx-0"
            type="submit"
          />
        </v-layout>

      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { NerveButton } from 'nerve-ui-components';
import PasswordField from '@/components/shared/PasswordField.vue';
import { VALIDATION_REGEX, URL_MATCHER } from '@/constants';
import i18n from '@/i18n';
import Logger from '@/utils/logger';

export default {
  name: 'LoginForm',
  components: {
    PasswordField,
    NerveButton,
  },
  data: () => ({
    valid: false,
    username: '',
    password: '',
  }),
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('login.fieldIsRequired'),
        email: (value) => this.isLdapActive || (VALIDATION_REGEX.EMAIL.test(value) || this.$t('login.emailRegexMsg')),
      };
    },
    isLdapActive() {
      return this.$store.getters['ldap/isLdapActive']();
    },
    getActiveNotification() {
      return this.$store.getters['notifications/getActiveNotification'];
    },
    isEmailValid() {
      return this.isLdapActive || VALIDATION_REGEX.EMAIL.test(this.username);
    },
  },
  created() {
    this.$nextTick(() => {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.passwordField?.$refs.password.$el.addEventListener('input', () => {
        this.$refs.form.validate();
      });
    });
  },
  methods: {
    async login() {
      try {
        const loginData = {
          externalLogin: false,
          identity: this.username,
          secret: this.password,
        };
        await this.$store.dispatch('auth/login', loginData);
        await this.$store.dispatch('auth/is_user_logged');
        await this.$store.dispatch('utils/_api_request_handler/show_permanent_toast', false);
        // eslint-disable-next-line prefer-destructuring
        i18n.locale = JSON.parse(localStorage.getItem('userDetails')).preferredLanguage.split('_')[0];
        // temporary place to connect to mqtt broker
        this.$mqtt.connect();

        if (this.$route.query.redirect) {
          await this.$router.push({ path: this.$route.query.redirect }).catch(() => {});
        } else {
          await this.$router.push({ name: 'Nodes', query: { tab: 0 } }).catch(() => {});
          await this.$store.dispatch('notifications/getActiveNotificationAfterLogin');
          if (this.getActiveNotification.active && !this.getActiveNotification.showBeforeLogin) {
            if (this.getActiveNotification.backgroundImage) {
              this.getActiveNotification.backgroundImage = await this.$store.dispatch('notifications/getImage');
            }
            const textMessageWithLinks = this.getActiveNotification.textMessage.replace(URL_MATCHER, '<a href="$1" target="_blank">$1</a>');
            this.$store.dispatch('utils/_api_request_handler/show_notification_dialog', {
              title: this.getActiveNotification.headerText,
              subTitle: this.getActiveNotification.textMessage,
              backgroundImage: this.getActiveNotification.backgroundImage,
              textMessageWithLinks,
            });
          }
          // eslint-disable-next-line prefer-destructuring
          i18n.locale = JSON.parse(localStorage.getItem('userDetails')).preferredLanguage.split('_')[0];
        }
      } catch (e) {
        Logger.error(e);
      }
    },
    showForgotPassword() {
      this.$router.push({ name: 'Forgot Password' });
    },
  },
};
</script>

<style lang="scss">
  #loginForm {
    #leftPart {
      padding: 70px;
    }
  }
</style>
